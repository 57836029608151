import {Gesture} from '../interfaces/index';

let touchstartX = 0;
let touchstartY = 0;
let touchendX = 0;
let touchendY = 0;

function processGesture() {
  const gestureData: Gesture = {
    isSwipe: false,
    direction: null,
    absoluteDiffX: Math.abs(touchstartX - touchendX),
    absoluteDiffY: Math.abs(touchstartY - touchendY),
  };
  const isSideSwipe = gestureData.absoluteDiffX > gestureData.absoluteDiffY;

  if (gestureData.absoluteDiffX === 0 && gestureData.absoluteDiffY === 0) {
    gestureData.isSwipe = false;
    gestureData.direction = null;
  } else if (isSideSwipe) {
    gestureData.isSwipe = true;
    gestureData.direction = touchendX < touchstartX ? 'left' : 'right';
  } else if (!isSideSwipe) {
    gestureData.isSwipe = true;
    gestureData.direction = touchendY < touchstartY ? 'up' : 'down';
  }

  return gestureData;
}

type GestureCallback = (g: Gesture) => void;
function swipeEventHelper(
  swipeElement: HTMLElement,
  swipeCallback: GestureCallback
): void {
  //
  // EVENT LISTENERS
  // ------------------------------

  swipeElement.addEventListener(
    'touchstart',
    (event: TouchEvent): void => {
      // record start position
      touchstartX = event.changedTouches[0].screenX;
      touchstartY = event.changedTouches[0].screenY;
    },
    false
  );

  swipeElement.addEventListener(
    'touchend',
    (event: TouchEvent): void => {
      // record end position
      touchendX = event.changedTouches[0].screenX;
      touchendY = event.changedTouches[0].screenY;

      const gesture = processGesture();

      swipeCallback(gesture);
    },
    false
  );
}

export default swipeEventHelper;
